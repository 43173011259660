import { BUILD_ICON, COMPONENT_ICON } from "@dis/models";
import { RouteTreeNode } from "./nav-tree.component";

export const allowPublic = ['*'];
export const onlyAdmin = ['admin'];
export const onlyEditor = ['editor', 'admin'];
export const onlyViewer = ['viewer', 'editor', 'admin'];

/** Example file/folder data. */
export const appRoutes: RouteTreeNode[] = [
  {
    name: 'Home',
    icon: 'home',
    route: ['home'],
    acl: allowPublic,
  },
  {
    name: 'JTMS Dashboard',
    icon: 'dashboard',
    // route: ['dashboard','jobs-by-status'],
    acl: onlyViewer,
    children: [
      {
        name: 'Jobs by status',
        icon: 'team_dashboard',
        route: ['dashboard', 'jobs-by-status'],
        acl: []
      },
      {
        name: 'Staged Jobs',
        icon: 'view_list',
        route: ['dashboard', 'staged-jobs'],
        acl: []
      },
      {
        name: 'Jobs gantt view',
        icon: 'waterfall_chart',
        route: ['dashboard', 'jobs-gantt'],
        acl: []
      },
      {
        name: 'Tools by type',
        icon: 'bar_chart',
        route: ['dashboard', 'tools-bar'],
        acl: []
      }
    ]
  },
  {
    name: 'JTMS Tables',
    icon: 'table',
    children: [
      {
        name: '0_Quotes',
        icon: 'request_quote',
        route: ['quotes', 'table'],
        acl: onlyViewer
      },
      {
        name: '1_Projects',
        icon: 'map',
        route: ['projects', 'table'],
        acl: onlyViewer,
      },
      {
        name: '2_Jobs',
        icon: 'work',
        route: ['jobs', 'table'],
        acl: onlyViewer
      },
      {
        name: '3_Builds',
        icon:  BUILD_ICON.BUILD,
        route: ['builds', 'table'],
        acl: onlyViewer
      },
      {
        name: '4_Assemblies',
        icon: 'group_work',
        route: ['assembly', 'table'],
        acl: onlyViewer
      },
      {
        name: '5_Components',
        icon: COMPONENT_ICON.COMPONENT,
        route: ['components', 'table'],
        acl: onlyViewer
      },
      {
        name: '6_Parts',
        icon: 'extension',
        route: ['parts', 'table'],
        acl: onlyViewer
      },
    ]
  },
  {
    name: 'Office',
    icon: 'apartment',
    acl: onlyViewer,
    children: [
      {
        name: 'Scan Manager',
        icon: 'scanner',
        route: ['office','scan-manager'],
        acl: onlyViewer
      },
      {
        name: 'Invoices',
        icon: 'receipt_long',
        route: ['office','invoices'],
        acl: onlyViewer
      },

    ],
  },
  {
    name: 'Inventory',
    icon: 'inventory_2',
    // route: ['inventory'],
    acl: onlyViewer,
    children: [
      {
        name: 'Map',
        icon: 'map',
        route: ['inventory', 'map'],
        acl: [],
      },
      {
        name: 'Move item',
        icon: 'move',
        route: ['inventory', 'move-item'],
        acl: [],
      },
    ]
  },
  {
    name: 'Containers',
    icon: 'takeout_dining',
    acl: onlyViewer,
    children: [
      {
        name: 'Table',
        icon: 'list',
        route: ['containers','table'],
        acl: [],
      },
      {
        name: 'Map',
        icon: 'map',
        route: ['containers', 'map'],
        acl: [],
      },
    ]
  },
  {
    name: 'Shop',
    icon: 'store',
    acl: onlyViewer,
    children: [
      {
        name: 'Cal Tool List',
        icon: 'list',
        route: ['shop','calibratedTools'],
        acl: [],
      },
      {
        name: 'TPI Matrix',
        icon: 'groups',
        route: ['shop','tpi-matrix'],
        acl: [],
      },
      {
        name: 'Status Lights',
        icon: 'wb_twilight',
        route: ['shop','status-lights'],
        acl: [],
      }
    ]
  },
  {
    name: 'Meeting Room',
    icon: 'meeting_room',
    route: ['dashboard', 'meeting-mode'],
    acl: onlyViewer,
  },
  {
    name: 'User',
    icon: 'account_circle',
    route: ['user'],
    acl: allowPublic,
  },
  {
    name: 'Users',
    icon: 'people',
    acl: onlyAdmin,
    route: ['users']
  }
];
