import { enableProdMode } from '@angular/core';

import * as Sentry from '@sentry/angular';

import { environment } from './environments/environment';
import { bootstrapApplication } from '@angular/platform-browser';
import { AppComponent } from './app/app.component';
import { appConfig } from './app/app.config';

if (environment.production) {
  enableProdMode();
  Sentry.init({
    dsn: environment.sentry.dsn,
    release: environment.sentry.release,
    environment: environment.name,
  });
}

// platformBrowserDynamic()
//   .bootstrapModule(AppModule)
//   .catch(err => console.log(err));

bootstrapApplication(AppComponent, appConfig).catch((err) =>
  console.error(err),
);
