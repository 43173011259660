@if ((claims$ | async); as claims) {
  @if (treeNodes$ | async) {
    <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
      <mat-tree-node *matTreeNodeDef="let node"
        matTreeNodeToggle
        matTreeNodePadding
        [routerLink]="transformRouteLink(node, claims?.user_id)"
        routerLinkActive="active">
        <mat-icon class="type-icon" [attr.aria-label]="node.icon + 'icon'" matListIcon>
          {{ node.icon }}
        </mat-icon>
        {{node.name}}
      </mat-tree-node>
      <mat-tree-node *matTreeNodeDef="let node; when: hasChild"
        matTreeNodeToggle
        matTreeNodePadding
        [class.parent]="hasChild"
        routerLinkActive="active" >
        <mat-icon class="type-icon" [attr.aria-label]="node.icon + 'icon'" matListIcon>
          {{ node.icon }}
        </mat-icon>
        {{node.name}}
      </mat-tree-node>
    </mat-tree>
  }
} @else {
  <mat-nav-list>
    <a mat-list-item routerLink="auth/signin" routerLinkActive="active">Login
      <mat-icon matListIcon>login</mat-icon>
    </a>
  </mat-nav-list>
}

<ng-template #signin>
  <mat-nav-list>
    <a mat-list-item routerLink="auth/signin" routerLinkActive="active">Login
      <mat-icon matListIcon>login</mat-icon>
    </a>
  </mat-nav-list>
</ng-template>
