import { AuthState } from "@dis/auth";
import { LayoutState, TableState, QuotesState, ProjectsState, JobsState, BuildsState, AssembliesState, ComponentsState, PartsState, ContainersState, InvoicesState, EquipmentState } from "@dis/shared";
import { withNgxsReduxDevtoolsPlugin } from "@ngxs/devtools-plugin";
import { withNgxsFormPlugin } from "@ngxs/form-plugin";
import { withNgxsRouterPlugin } from "@ngxs/router-plugin";
import { withNgxsStoragePlugin } from "@ngxs/storage-plugin";
import { provideStore } from "@ngxs/store";
import { NGXS_APP_STORAGE_CONFIG } from "./ngxs-storage-config";

export function provideNgxsCore() {
  return [
    provideStore([
      AuthState,
      /**
       * App Context related states
       */
      LayoutState,
      TableState,
      /**
       * JTMS Collection States
       */
      QuotesState,
      ProjectsState,
      JobsState,
      BuildsState,
      AssembliesState,
      ComponentsState,
      PartsState,
      ContainersState,
      InvoicesState,
      EquipmentState,
    ],
      withNgxsStoragePlugin(
        NGXS_APP_STORAGE_CONFIG
      ),
      withNgxsRouterPlugin(),
      withNgxsFormPlugin(),
      withNgxsReduxDevtoolsPlugin()
    ),
  ]
}
