import { ChangeDetectionStrategy, Component, OnInit, inject, isDevMode } from '@angular/core';
import { MainLayoutComponent } from './layout/main-layout/main-layout.component';
import { MatIconRegistry } from '@angular/material/icon';
import { NgswService } from './core/services/ngsw.service';
import { FcmService } from './core/services/fcm.service';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [MainLayoutComponent],
  template: `<app-main-layout />`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {
  title = 'Job & Tool Management System';

  fcmService = inject(FcmService);
  iconRegistry = inject(MatIconRegistry);
  ngswService = inject(NgswService);
  ngOnInit(): void {
    this.iconRegistry.setDefaultFontSetClass('material-symbols-outlined');
    // Angular Serviceworker initilization;
    // Second release
    if (!isDevMode()) {
      this.ngswService.initSwUpdate();
      this.ngswService.initSwPush();
    }
  }
}
