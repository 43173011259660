import { Component, OnInit, ViewChild, OnDestroy, ChangeDetectionStrategy, signal } from '@angular/core';
import { MatSidenav, MatSidenavModule } from '@angular/material/sidenav';

import { Subscription } from 'rxjs';

import { Router, NavigationStart, NavigationEnd, NavigationCancel, NavigationError, Event, RouterOutlet } from '@angular/router';

import { SidenavService } from 'projects/jtms-front-end/src/app/layout/main-layout/sidenav/sidenav.service';
import { MatProgressBarModule } from '@angular/material/progress-bar';

import { NavTreeComponent } from '../nav-tree/nav-tree.component';
import { select } from '@ngxs/store';
import { LayoutState } from '@dis/shared';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.css'],
  standalone: true,
  imports: [
    MatSidenavModule,
    MatProgressBarModule,
    RouterOutlet,
    NavTreeComponent,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidenavComponent implements OnInit, OnDestroy {

  fixedInViewPort = false;

  // loading$ = new BehaviorSubject(false);
  isHandset = select(LayoutState.isHandset);
  loading = signal<boolean>(false);

  // Viewport params
  @ViewChild('sidenav', { static: true }) public sidenav!: MatSidenav;

  routerSubscription!: Subscription;

  constructor(
    private router: Router,
    private sidenavService: SidenavService,
  ) {
    this.router.events.subscribe((event: Event) => {
      switch (true) {
        case event instanceof NavigationStart: {
          // this.loading$.next(true);
          this.loading.set(true);
          if (this.isHandset()) {
            this.sidenavService.close().then(() => { });
          }
          break;
        }
        case event instanceof NavigationEnd:
        case event instanceof NavigationCancel:
        case event instanceof NavigationError: {
          this.loading.set(false);
          // this.loading$.next(false);
          break;
        }
        default: {
          break;
        }
      }
    });
  }

  ngOnInit() {
    // Store Sidenav to service
    this.sidenavService.setSidenav(this.sidenav);
  }

  ngOnDestroy() {
    this.routerSubscription.unsubscribe();
  }

}
