<mat-toolbar role="heading" class="header" color="primary">

  <button type="button" aria-label="Toggle sidenav" mat-icon-button (click)="sidenavService.toggle()">
    <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
  </button>

  @if (!isHandset()) {
  <span>Job & Tool Management System</span>
  }
  @if (isHandset()) {
  <span>JTMS</span>
  }
  @if (!isHandset()) {
  <img class="logo" src="../../../assets/images/logo/drilling-logo.jpg" alt="DIS_Logo">
  }

  <span class="span"></span>

  @if(pressureTest$ | async; as activeTest) {
  @if(activeTest.testInProgress) {
  <div>
    <div>
      <span>Testing {{activeTest?.build?.buildNumber}} | {{activeTest?.build?.serialNumber}} |
        {{activeTest?.build?.assemblyNumber}}</span>
    </div>
    <div>
      <mat-progress-bar style="max-width: 500px;" mode="indeterminate" color="warn"></mat-progress-bar>
    </div>
    <div>
      <span>{{activeTest?.build?.projectNumber}} | {{activeTest?.build?.jobNumber}}</span>
    </div>

  </div>


  }
  }

  <span class="span"></span>

  @if (user(); as user) {
  <img class="user-photoURL" [src]="user.picture" [routerLink]="['/user', user.user_id]">
  }

  <button type="button" aria-label="About JTMS" mat-icon-button [routerLink]="['/about']">
    <mat-icon aria-label="About JTMS">info</mat-icon>
  </button>

</mat-toolbar>
