import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Observable } from 'rxjs';

import { SidenavService } from '../sidenav/sidenav.service';

import { select } from '@ngxs/store';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { RouterLink } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { LayoutState } from '@dis/shared';
import { PressureTestCollection } from '../../../db/pressure-test.collection';
import { AsyncPipe } from '@angular/common';
import { AuthState } from '@dis/auth';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
  standalone: true,
  imports: [
    AsyncPipe,
    MatButtonModule,
    MatIconModule,
    MatProgressBarModule,
    MatToolbarModule,
    RouterLink,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent {

  isHandset = select(LayoutState.isHandset);

  pressureTest$: Observable<any>;

  user = select(AuthState.claims);

  constructor(
    private pressureTestService: PressureTestCollection,
    public sidenavService: SidenavService,
  ) {
    this.pressureTest$ = this.pressureTestService.doc$('activeTest');
  }

}
